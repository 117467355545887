import invariant from 'invariant';
import { ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { ApiListingLocationType } from 'api/types/ApiListingLocationType';
import { Box } from 'components/Box';
import { Icon } from 'components/Icon/Icon';
import { IconName } from 'components/Icon/IconName';
import { colors, mediaRetina } from 'theme/theme';
import {
  APPLICATION_STATUSES_ALL,
  getColor as getStatusColor,
} from 'utils/constants/ats';
import { LOCATION_TYPE } from 'utils/constants/general/locationType';
import { STATUSES } from 'utils/constants/general/statuses';

const ALL_STATUSES = {
  ...STATUSES,
  ...APPLICATION_STATUSES_ALL,
  NEW: getText('New Application'),
  INACTIVE: getText('Inactive'),
  ACTIVE: getText('Active'),
  // resume recommender statuses:
  ADDED_NOTE: getText('Added Note'),
  CONTACTED_SUGG_APPL: getText('Contacted'),
  RATING_CHANGED: getText('Ranking Updated'),
  RECOMMENDED: getText('Recommended On'),
};

export type AllStatusType = keyof typeof ALL_STATUSES;

// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getStatus = (status: any) => ALL_STATUSES[status];

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function ListingStatusLabelTag(props: Record<string, any>) {
  return (
    <Box
      data-qa-id="listing-status"
      display="inline-block"
      py="2px"
      px="5px"
      borderRadius="4px"
      letterSpacing="1px"
      textTransform="uppercase"
      lineHeight={1}
      fontSize={12}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}

const getLocationStyles = (type: ApiListingLocationType) => {
  switch (type) {
    case 'REMOTE':
      return {
        bg: colors.brightPlum,
        color: 'white',
      };

    case 'HYBRID':
      return {
        bg: colors.mediumSkyBlue,
        color: 'white',
      };

    case 'ONSITE':
    default:
      return {
        bg: colors.brandGreen,
        color: 'white',
      };
  }
};

export function LocationLabel({
  type,
  ...props
}: {
  type: ApiListingLocationType;
}) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <ListingStatusLabelTag {...getLocationStyles(type)} {...props}>
      {LOCATION_TYPE[type]}
    </ListingStatusLabelTag>
  );
}

const TagContainer = styled.div`
  display: flex;
`;

export const TagNew = styled.div<{
  $bg: string;
  $borderColor: string;
  $color: string;
  $hasAnnex?: boolean;
  $thickLeftBorder?: boolean;
}>`
  align-items: center;
  display: inline-flex;
  gap: 4px;
  padding: 2px 5px;
  border-radius: ${(props) => (props.$hasAnnex ? '4px 0 0 4px' : '4px')};
  letter-spacing: 0.3px;
  line-height: 12px;
  font-size: 14px;
  font-weight: 400;
  border: 1px solid ${(props) => props.$borderColor};
  border-left-width: ${(props) => (props.$thickLeftBorder ? '5px' : '1px')};
  color: ${(props) => props.$color};
  background-color: ${(props) => props.$bg};
  white-space: nowrap;
`;

const Annex = styled.div<{
  $borderColor: string;
}>`
  padding: 2px 5px;
  border-radius: 0 4px 4px 0;
  letter-spacing: 0.3px;
  line-height: 12px;
  font-size: 14px;
  font-weight: 600;
  border: 1px solid ${(props) => props.$borderColor};
  color: ${colors.mediumContentGrey};
  background-color: white;
`;

type TagCombinedProps = {
  annex?: string;
  bg: string;
  children?: ReactNode;
  color: string;
  iconName?: IconName;
};

export function TagCombined({
  annex,
  bg,
  children,
  color,
  iconName,
}: TagCombinedProps) {
  return (
    <TagContainer>
      <TagNew
        $color={color}
        $bg={bg}
        $borderColor={bg}
        $hasAnnex={Boolean(annex)}
        data-qa-id="listing-status"
      >
        {iconName && <Icon name={iconName} size={13} />}
        {children}
      </TagNew>
      {annex && <Annex $borderColor={bg}>{annex}</Annex>}
    </TagContainer>
  );
}

export function LocationLabelCombined({
  location,
  type,
}: {
  location?: string;
  type: ApiListingLocationType;
}) {
  const styles = getLocationStyles(type);
  return (
    <TagCombined
      color={styles.color}
      bg={styles.bg}
      annex={location}
      iconName="location-filled"
    >
      {LOCATION_TYPE[type]}
    </TagCombined>
  );
}

const StyledStatus = styled.div<{ $indicatorStyle?: string }>`
  position: relative;
  font-size: 14px;
  letter-spacing: 1.8px;
  color: ${colors.lightContentGrey};
  text-transform: uppercase;
  line-height: 1.35;
  padding-left: 21px;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 4px;
    border-radius: 50%;
    ${(props) =>
      props.$indicatorStyle === 'outline'
        ? css`
            width: 8px;
            height: 8px;
            border: solid 1px ${props.color};
            ${mediaRetina} {
              border-width: 1.5px;
            }
          `
        : css`
            width: 11px;
            height: 11px;
            background-color: ${props.color};
          `};
  }
`;

// return the first application status
const getApplicationStatus = (status: ReadonlyArray<string> | string) => {
  // TODO: Fix this the next time the file is edited.
  // @ts-expect-error TS(2322): Type 'string | readonly string[]' is not assignabl... Remove this comment to see the full error message
  // eslint-disable-next-line no-param-reassign
  if (!Array.isArray(status)) status = [status];
  // @ts-expect-error TS(2339): Property 'find' does not exist on type 'string | r... Remove this comment to see the full error message
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return status.find((s: any) => APPLICATION_STATUSES_ALL[s]);
};

type Props = {
  listingStatus: ReadonlyArray<string> | string;
  asTag?: boolean;
  forceSolid?: boolean;
  colorOverride?: string | null;
  textColorOverride?: string | null;
};

export function ListingStatusLabel({
  listingStatus,
  asTag,
  forceSolid,
  colorOverride,
  textColorOverride,
  ...props
}: Props) {
  let status;
  let color = colors.alertRed;
  let textColor = 'white';
  let indicatorStyle = forceSolid ? 'outline' : 'solid';

  if (listingStatus.includes('PUBLISHED')) {
    status = 'PUBLISHED';
    color = colors.brandGreen;
    indicatorStyle = 'solid';
  } else if (listingStatus.includes('REJECTED')) {
    status = 'REJECTED';
    color = colors.brandYellow;
    textColor = colors.darkContentGrey;
  } else if (listingStatus.includes('DRAFT')) {
    status = 'DRAFT';
    color = colors.brandYellow;
    textColor = colors.darkContentGrey;
  } else if (listingStatus.includes('PENDING')) {
    status = 'PENDING';
    color = colors.brandYellow;
    textColor = colors.darkContentGrey;
  } else if (listingStatus.includes('INVOICE')) {
    status = 'INVOICE';
    color = colors.brandGreen;
  } else if (listingStatus.includes('ARCHIVED')) {
    status = 'ARCHIVED';
    color = colors.brandYellow;
    textColor = colors.darkContentGrey;
  } else if (listingStatus.includes('FROZEN')) {
    status = 'FROZEN';
  } else if (listingStatus.includes('HIDDEN')) {
    status = 'HIDDEN';
    color = colors.brandYellow;
    textColor = colors.darkContentGrey;
  } else if (listingStatus.includes('PULLED')) {
    status = 'PULLED';
  } else if (listingStatus.includes('DELETED')) {
    status = 'DELETED';
  } else if (listingStatus.includes('CHECKOUT')) {
    status = 'CHECKOUT';
    color = colors.brandYellow;
    textColor = colors.darkContentGrey;
  } else if (listingStatus.includes('SPAM')) {
    status = 'SPAM';
  } else if (listingStatus.includes('APPROVED')) {
    status = 'APPROVED';
    color = colors.brandGreen;
    indicatorStyle = 'solid';
  } else if (getApplicationStatus(listingStatus)) {
    const statusColor = getStatusColor(
      getApplicationStatus(listingStatus) || 'APPLIED',
    );
    color = statusColor.bg;
    textColor = statusColor.color;
  } else if (listingStatus === 'ACTIVE') {
    color = colors.brandGreen;
  } else if (listingStatus === 'INACTIVE') {
    color = colors.elementGrey;
    textColor = colors.darkContentGrey;
  } else if (listingStatus === 'NEW') {
    color = colors.brandBlue;
    textColor = colors.white;
    status = 'NEW';
  } else if (listingStatus === 'ADDED_NOTE') {
    color = colors.brandGreen;
    textColor = colors.white;
    status = 'ADDED_NOTE';
  } else if (listingStatus === 'CONTACTED_SUGG_APPL') {
    color = colors.brandBlue;
    textColor = colors.white;
    status = 'CONTACTED_SUGG_APPL';
  } else if (listingStatus === 'RATING_CHANGED') {
    color = colors.brightPlum;
    textColor = colors.white;
    status = 'RATING_CHANGED';
  } else if (listingStatus === 'RECOMMENDED') {
    color = colors.decorativePlum;
    textColor = colors.white;
    status = 'RECOMMENDED';
  }

  if (
    typeof listingStatus === 'string' &&
    Object.keys(ALL_STATUSES).includes(listingStatus)
  ) {
    status = listingStatus;
  }

  if (colorOverride) {
    color = colorOverride;
  }
  if (textColorOverride) {
    textColor = textColorOverride;
  }

  invariant(status, 'Invalid status');
  return asTag ? (
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/jsx-props-no-spreading
    <ListingStatusLabelTag bg={color} color={textColor} {...props}>
      {getStatus(status)}
    </ListingStatusLabelTag>
  ) : (
    <StyledStatus
      data-qa-id="listing-status"
      color={color}
      $indicatorStyle={indicatorStyle}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {getStatus(status)}
    </StyledStatus>
  );
}
